//<script>
var app = angular.module('MyApp', ['ngGrid','xeditable','ui.bootstrap','ngBootstrap']);

app.directive('chosen', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) 
        {
            $(element).chosen({width: "100%"});
        }
    };
});

app.directive('tooltip', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs)
        {
            tippy( element[0], { content: attrs.tippy });
        }
    };
});

$(document).ready(function (){

    //$('*[data-role="tooltip"]').tooltip({html: true});
	//fix for tinymce and modal bootstrap.
	$(document).on('focusin', function(e) {
	    if ($(e.target).closest(".mce-window").length) {
	        e.stopImmediatePropagation();
	    }
	});

});

//Services
app.service('newTab', function() {//todo finish here?
    this.open = function ( url, title, sizeCode, onClose) 
    {
        return newTab(url,title,sizeCode, function (newTabIdSelector){
    		var id = newTabIdSelector.replace('#','');	
			var $injector = angular.injector(['ng', 'MyApp']);
			$injector.invoke(function($rootScope, $compile) {
			  $compile(document.getElementById(id))($rootScope);
			  $rootScope.$digest();
			});
    }, onClose);
        
    };
});

var makeAngularCallback = function (newTabIdSelector){
	var id = newTabIdSelector.replace('#','');	
	var $injector = angular.injector(['ng', 'MyApp']);
	$injector.invoke(function($rootScope, $compile) {
	  $compile(document.getElementById(id))($rootScope);
	  $rootScope.$digest();
	});
};

var refreshTabByUrl = function (url, $appendOn) {
	
	$
	.get(url)
	.done(function(html) {
		$appendOn.html(html);
			
		var $injector = angular.injector(['ng', 'MyApp']);
		$injector.invoke(function($rootScope, $compile) {
		  $compile($appendOn.get(0))($rootScope);
		  $rootScope.$digest();
		});
	})
	
}