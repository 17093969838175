
app.controller('GalleryCtrl', 
					['$scope', '$http', '$filter', '$timeout', 'newTab',
					function ($scope, $http, $filter,  $timeout, newTab, $apply) {
		
		$scope.items = [];
		$scope.refreshUrl = '';
		
		Dropzone.autoDiscover = false;
		
		$scope.dropzoneConfig = {
			
			options: { 
				url: 'upload.php',
				paramName: "file", // The name that will be used to transfer the file
				maxFilesize: 2, // MB
				acceptedFiles: 'image/x-png,image/png,image/jpg,image/jpeg,application/pdf,application/x-download',
				dictDefaultMessage: 'Drop files here or click to upload - PNG, JPG or PDF only and 1MB max'
	    	},
	    	
	    	eventHandlers: {
				sending: function (file, xhr, formData) 
				{
					
				},
				success: function (file, response) 
				{
					
	      		},
	      		complete: function ()
	      		{
					$scope.getItems($scope.refreshUrl);
	      		}
	    	}
	    	
		};
		
		$scope.setDropzoneUrl = function (newUrl)
		{
			$scope.dropzoneConfig.options.url = newUrl;
		};
		
		$scope.init = function (url)
		{
			$scope.refreshUrl = url;
			$scope.getItems(url);
		};
		
		$scope.getItems = function (url)
		{
	    	$http({
                url: url,
                method: "POST",
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }).success( function(data, status, headers, config) {
				$scope.items = data.items;
            });
		};
				
		    
	    $scope.newTab = function (url,title,sizeCode)
	    {
	    	newTab.open(url,title,sizeCode);
	    };
		
}]);

app.directive('dropzone', function () 
{
	return function (scope, element, attrs) 
	{
	var config = scope[attrs.dropzone],
		dropzone;

    	// create a Dropzone for the element with the given options
	    dropzone = new Dropzone('#'+element[0].id, config.options);
    
    	// bind the given event handlers
		angular.forEach(config.eventHandlers, function (handler, event) {
			dropzone.on(event, handler);
		});
	};
});

app.directive('lightgallery', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      if (scope.$last) {

        // ng-repeat is completed
        element.parent().lightGallery();
      }
    }
  };
});