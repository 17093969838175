//<script>
//x-editable
app.run(function(editableOptions) {
  editableOptions.theme = 'bs3'; // bootstrap3 theme. Can be also 'bs2', 'default'
});

app.controller('MainControllerEditable', ['$scope', '$http', '$filter', '$timeout', 'newTab', function ($scope, $http, $filter,  $timeout, newTab, $apply) {
		
		$scope.dropDown = {};
		$scope.picker = {};
		refreshQueue = [];
				
		$scope.registerField = function(fieldName, val, formId, rowId, type, objDropdown) 
		{
		var key = 'form_'+formId+'_'+fieldName;
		
			if(typeof $scope[key] != 'object')
				$scope[key] = {};
				
			if(type=='bsdate')
			{
				if(typeof $scope.picker[key] != 'boolean')
					$scope.picker[key] = false;
					
				if(val.split(',').length == 3)
				{
					$scope[key] = val.split(',').join('-');
				}else{
					$scope[key] = '';
				}
			}else if(type=='select' || type=='multiple' || type=='checklist')
			{
				objDropdown = objDropdown || '';
				objDropdown = atob(objDropdown);
				objDropdown = JSON.parse(objDropdown);	
				
				if(type=='multiple')
					$scope[key] = JSON.parse(atob(val));
				else
					$scope[key] = val;
					
				$scope.dropDown[key] = objDropdown;
			}else if(type=='checkbox'){
				$scope[key] = val == '1' ? true : false;
			}else{
				$scope[key] = val;
			}
			
			$scope.watchField(key, formId, rowId);
		};
		
		$scope.openPicker = function(fieldName, formId) 
		{
		var key = 'form_'+formId+'_'+fieldName;
		 
			$timeout(function() 
			{
				$scope.picker[key] = true;
			}, 200);
		};
				  
		$scope.closePicker = function(fieldName, formId) 
		{
		var key = 'form_'+formId+'_'+fieldName;
		
			$scope.picker[key] = false;
		};
		
		//Watch
		$scope.watchField = function (fieldName, formId, rowId)
		{
			$scope.$watch(fieldName, function(newVal, oldVal) 
			{
			    if (newVal !== oldVal) 
			    {
			    	if(newVal instanceof Date)
			    	{
			    		function dateToMysql(d)
			    		{
		    			var year, month, day, d = new Date(d);
		    			
					        year = String(d.getFullYear());
					        month = String(d.getMonth() + 1);
					        
					        if (month.length == 1) 
					        {
					            month = "0" + month;
					        }
					        
					        day = String(d.getDate());
					        
					        if (day.length == 1) 
					        {
					            day = "0" + day;
					        }
					        
					        return year + "-" + month + "-" + day;
			    		}
    					
			    		newVal = dateToMysql(newVal);
			    		oldval = dateToMysql(oldVal);
			    		
			    	}else if(newVal===true || newVal === false)
			    		{
			    			newVal = (newVal)?1:0;
			    		}
			    	
			    	$http({
		                url: "/form/updatefield/"+1,
		                method: "POST",
		                data: { newVal: newVal,
		                		oldVal: oldVal,
		                		fieldName: fieldName.replace('form_'+formId+'_', ''),
		                		formId: formId,
		                		rowId: rowId },
		                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		            }).success( function(data, status, headers, config) {
						var nextAction = refreshQueue.pop();
						
						if(typeof nextAction == 'function')
							nextAction();
		            });
			    }
			});
		};
		
		//Dropdown
	    $scope.showDropDown = function(fieldName, formId) 
	    {
	    var key = 'form_'+formId+'_'+fieldName,
	    	objDropdown = $scope.dropDown[key];
	    
			for(var i = 0, len = objDropdown.length; i < len; i++)
				if(objDropdown[i].value == $scope[key])
					return objDropdown[i].label;
					
			return 'Not Set'; 
		};
		
	    $scope.showDropDownMulti = function(fieldName, formId) 
	    {
	    var key = 'form_'+formId+'_'+fieldName,
	    	objDropdown = $scope.dropDown[key],
	    	selected = [];
			
			for(var i = 0, len = objDropdown.length; i < len; i++)
				if($scope[key] && $scope[key].indexOf(objDropdown[i].value) > -1)
					selected.push(objDropdown[i].label); 
						
			return selected.length > 0 ? selected.join(', ') : 'Not set';
		};
		
		$scope.refreshField = function (memId)
		{
			refreshQueue.push(function (){
				$http({
	                url: "/form/refreshfield",
	                method: "POST",
	                data: { memId: memId },
	                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	            }).success( function(data, status, headers, config) {
					angular.forEach(data.values, function (v,k){
						if(angular.isObject(v))
						{
							$scope.dropDown[k] = v.dropdown;							
							$scope[k] = v.value;							
						}else{
							$scope[k] = v;
						}
						
					});
	           });
			});
		};
		
		    
    $scope.newTab = function (url,title,sizeCode)
    {
    	newTab.open(url,title,sizeCode);
    };
    
}]);
